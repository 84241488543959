<template>
  <vs-popup
    class="user-edit-popup c-popup--mnh-350"
    id="popup"
    title=" Change Group"
    @close="open = false"
    :active.sync="open"
    :buttons-hidden="true"
  >
    <div class="vx-row mb-3">
      <div class="vx-col md:w-full w-full md:mb-0">
        <label class="small">Select Parent To Move</label>
        <v-select
          :getOptionLabel="(parent) => parent.Path"
          :options="parents"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="form"
          class="w-full"
          name="movetoparent"
        />
      </div>
    </div>
    <div class="flex flex-wrap items-center justify-end mt-8">
      <vs-button
        id="save_change"
        @click="onCLickSave"
        button="submit"
        class="ml-auto mt-2"
        >Save Changes</vs-button
      >
      <!--      <vs-button @click="name = ''" class="ml-4 mt-2" type="border" color="warning">Clear</vs-button>-->
    </div>
  </vs-popup>
</template>

<script>
import { RepositoryFactory } from '@/repository/RepositoryFactory';
const NodeRepository = RepositoryFactory.get('node');
import ErrorMessages from '@/mixins/ErrorMessages';
import SuccessMessage from '@/mixins/SuccessMessage';
import vSelect from 'vue-select';

function initialState() {
  return {
    open: false,
    name: '',
    parentId: null,
    parentNode: '',
    parents: [],
    form: {},
    nodeData: {},
    id: '',
  };
}
export default {
  name: 'ChangeGroup',
  data() {
    return initialState();
  },
  components: {
    'v-select': vSelect,
  },
  mixins: [ErrorMessages, SuccessMessage],

  methods: {
    async openPopup(node) {
      await Object.assign(this.$data, initialState());
      this.nodeData = node;
      // this.id = this.nodeData.RelationId;
      this.id = this.nodeData.Id;
      //   this.id = this.nodeData[0].ParentId
      // console.log(this.nodeData.Id);
      this.loadTree();
      this.open = true;

      //   this.parents = data.map(m=>{
      //       m.Name = m.Name[0].Value
      //       return m
      //   })
    },
    async loadTree() {
      const parents_data = (await NodeRepository.getParents(this.id)).data;
      this.parents = parents_data;
    },

    treeTranslations(Name) {
      if (!Name) {
        return '';
      }
      const name = Name.find((n) => {
        return n.Language === this.lan;
      });
      if (!!name) {
        return name.Value;
      } else {
        return Name.find((n) => {
          return n.Language === 'EST';
        }).Value;
      }
    },

    async onCLickSave() {
      const vue = this;
      this.$vs.loading({
        container: '#save_change',
        scale: 0.45,
      });
      try {
        await NodeRepository.moveToGroup(this.id, { ParentId: this.form.Id });
        this.$vs.loading.close();
        this.showSuccessMessage('Group move successfully!');
        this.open = false;
        await vue.$parent.dataRefresh();
      } catch (e) {
        this.$vs.loading.close('#save_change > .con-vs-loading');
        this.open = false;
        await vue.$parent.dataRefresh();
        this.showErrorMessage('Something went wrong!');
      }
      //this.$emit('notify')
      this.$vs.loading.close('#save_change > .con-vs-loading');
    },
  },
};
</script>

<style scoped>
</style>

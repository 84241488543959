<template>
  <div id="popup">
    <div>
      <!--      <template slot="actions">-->
      <vs-button
        class="mr-4"
        color="primary"
        type="filled"
        @click="onClickCreatePopUpOpen"
        >Add Translation</vs-button
      >
      <!--      </template>-->
      <div slot="no-body" class="mt-4">
        <vs-table
          :max-items="10"
          :data="translations"
          :sst="true"
          stripe
          class="table-dark-inverted table-auto vs-con-loading__container translation-table"
          >>

          <template slot="thead">
            <!--            <vs-th sort-key="name">TRANSLATION ID</vs-th>-->
            <vs-th sort-key="name">Language</vs-th>
            <vs-th sort-key="email">Value</vs-th>
            <vs-th>Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <!--<vs-td :data="data[indextr].TranslationId">
                {{ data[indextr].TranslationId }}
              </vs-td>-->

              <vs-td :data="data[indextr].Language">
                {{ data[indextr].Language }}
              </vs-td>
              <vs-td :data="data[indextr].Value">
                {{ data[indextr].Value }}
              </vs-td>
              <vs-td>
                <div class="vx-row">
                  <vx-tooltip text="Edit" position="left">
                    <!--                    <vs-button @click="popupActive=true"-->
                    <vs-button
                      @click="
                        onClickTranslationUpdate(data[indextr].TranslationId)
                      "
                      radius
                      color="primary"
                      type="border"
                      icon-pack="feather"
                      icon="icon-edit"
                    ></vs-button>
                  </vx-tooltip>
                  <vx-tooltip class="ml-3" text="Delete" position="left">
                    <!--                    <vs-button @click="popupActive=true"-->
                    <vs-button
                      @click="openConfirmDelete(data[indextr].TranslationId)"
                      radius
                      color="danger"
                      type="border"
                      icon-pack="feather"
                      icon="icon-trash-2"
                    ></vs-button>
                  </vx-tooltip>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>

    <vs-popup title="Translation Add" :active.sync="popupActiveAdd">
      <div class="vx-row mb-3">
        <div class="vx-col md:w-full w-full md:mb-0">
          <small class="label">Language</small>
          <!--              <vs-input v-model="form.Language" class="w-full" name="name" type="text" label=""/>-->
          <v-select
            v-model="form.Language"
            :options="availableLanArray"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          />
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col md:w-full w-full md:mb-0">
          <small class="label">Value</small>
          <vs-input
            v-model="form.Value"
            class="w-full"
            name="name"
            type="text"
            label=""
          />
        </div>
      </div>
      <div class="flex flex-wrap items-center justify-end mt-8">
        <vs-button @click="onClickAdd" button="submit" class="ml-auto mt-2"
          >Save Changes</vs-button
        >
        <!--            <vs-button @click="name = ''" class="ml-4 mt-2" type="border" color="warning">Clear</vs-button>-->
      </div>
    </vs-popup>

    <!--    translation popup update-->
    <vs-popup title="TRANSLATION UPDATE" :active.sync="popupActiveUpdate">
      <div class="vx-row mb-3">
        <div class="vx-col md:w-full w-full md:mb-0">
          <small class="label">Language</small>
          <vs-input
            v-model="updateSelectedForm.Language"
            class="w-full"
            name="name"
            type="text"
            label=""
          />
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col md:w-full w-full md:mb-0">
          <small class="label">Value</small>
          <vs-input
            v-model="updateSelectedForm.Value"
            class="w-full"
            name="name"
            type="text"
            label=""
          />
        </div>
      </div>
      <div class="flex flex-wrap items-center justify-end mt-8">
        <vs-button @click="onClickUpdate" button="submit" class="ml-auto mt-2"
          >Save Changes</vs-button
        >
        <!--        <vs-button @click="name = ''" class="ml-4 mt-2" type="border" color="warning">Clear</vs-button>-->
      </div>
    </vs-popup>
    <!--    translation popup update end-->
  </div>
</template>

<script>
import { RepositoryFactory } from '@/repository/RepositoryFactory';
const NodeRepository = RepositoryFactory.get('node');
const TranslationRepository = RepositoryFactory.get('translation');
import ErrorMessages from '@/mixins/ErrorMessages';
import SuccessMessage from '@/mixins/SuccessMessage';
import store from '../../../store/store';
import vSelect from 'vue-select';

function initialState() {
  return {
    open: false,
    name: '',
    id: null,
    translations: [],
    popupActiveAdd: false,
    popupActiveUpdate: false,
    form: {},
    updateSelectedForm: {},
    current_user_language_array: store.getters.current_user_language_array,
    availableLanArray: [],
  };
}

export default {
  name: 'TranslationsGenaralPopUp',
  data() {
    return initialState();
  },
  components: {
    'v-select': vSelect,
  },
  mixins: [ErrorMessages, SuccessMessage],
  methods: {
    async openPopup(node) {
      await Object.assign(this.$data, initialState());
      this.open = true;
      this.id = node.Id;
      this.loadGroup();
    },
    onClickCreatePopUpOpen() {
      this.form = {};
      this.popupActiveAdd = true;
    },
    async onClickAdd() {
      this.$vs.loading();
      try {
        await TranslationRepository.add(this.id, this.form);
        this.$vs.loading.close();
        this.showSuccessMessage('Translation create successfully!');
        // await vue.$parent.refreshTablelOad()
        await this.loadGroup();
        this.popupActiveAdd = false;
      } catch (e) {
        this.$vs.loading.close();
        // await vue.$parent.refreshTablelOad()
        this.showErrorMessage('Something went wrong!');
        this.popupActiveAdd = false;
      }
      this.$vs.loading.close();
    },
    async loadGroup() {
      this.$vs.loading();
      const groupDetails = (await NodeRepository.getOne(this.id)).data;
      this.translations = groupDetails[0].Name;
      const exists_languages = this.translations.map((m) => m.Language);
      this.availableLanArray = this.current_user_language_array.filter(
        function (obj) {
          return exists_languages.indexOf(obj) === -1;
        },
      );
      this.$vs.loading.close();
    },
    openConfirmDelete(id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm',
        text: 'Are you sure want to delete selected translation from group?',
        accept: this.onClickTranslationDelete,
        parameters: [id],
      });
    },
    async onClickTranslationDelete(id) {
      this.$vs.loading();
      try {
        await TranslationRepository.delete(id);
        this.$vs.loading.close();
        this.showSuccessMessage('Translation delete successfully!');
        // await vue.$parent.refreshTablelOad()
        await this.loadGroup();
      } catch (e) {
        this.$vs.loading.close();
        // await vue.$parent.refreshTablelOad()
        this.showErrorMessage('Something went wrong!');
      }
      this.$vs.loading.close();
    },
    async onClickTranslationUpdate(id) {
      this.$vs.loading();
      const updateSelectedTranslation = (await TranslationRepository.get(id))
        .data;
      this.updateSelectedForm = updateSelectedTranslation[0];
      this.$vs.loading.close();
      this.popupActiveUpdate = true;
    },
    async onClickUpdate() {
      this.$vs.loading();
      try {
        await TranslationRepository.update(
          this.updateSelectedForm.TranslationId,
          this.updateSelectedForm,
        );
        this.$vs.loading.close();
        this.showSuccessMessage('Translation update successfully!');
        // await vue.$parent.refreshTablelOad()
        await this.loadGroup();
        this.popupActiveUpdate = false;
      } catch (e) {
        this.$vs.loading.close();
        // await vue.$parent.refreshTablelOad()
        this.showErrorMessage('Something went wrong!');
        this.popupActiveUpdate = false;
      }
      this.$vs.loading.close();
    },
  },
};
</script>

<style scoped>
.translation-table tr:first-of-type .vs-button {
  display: none;
}
</style>

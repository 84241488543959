export default {
  methods: {
    showSuccessMessage(text) {
      this.$vs.notify({
        title: 'Success',
        text,
        color: 'success',
        position: 'top-right',
      });
    },
  },
};

<template>
  <vs-popup
    class="user-edit-popup"
    id="popup"
    title="Group Update"
    @close="open = false"
    :active.sync="open"
    :buttons-hidden="true"
  >
    <div class="vx-row mb-3">
      <div class="vx-col md:w-full w-full md:mb-0">
        <small class="label">Name</small>
        <vs-input
          v-model="name"
          class="w-full"
          name="name"
          type="text"
          label=""
        />
      </div>
    </div>
    <div class="flex flex-wrap items-center justify-end mt-8">
      <vs-button
        :disabled="name === ''"
        id="save_change"
        @click="onClickSave"
        button="submit"
        class="ml-auto mt-2"
        >Save Changes</vs-button
      >
      <!--      <vs-button @click="name = ''" class="ml-4 mt-2" type="border" color="warning">Clear</vs-button>-->
    </div>
  </vs-popup>
</template>

<script>
import { RepositoryFactory } from '@/repository/RepositoryFactory';
const NodeRepository = RepositoryFactory.get('node');
import ErrorMessages from '@/mixins/ErrorMessages';
import SuccessMessage from '@/mixins/SuccessMessage';

function initialState() {
  return {
    open: false,
    name: '',
    id: null,
    node: {},
    parent: {},
  };
}

export default {
  name: 'UpdateNodePopUp',
  data() {
    return initialState();
  },
  mixins: [ErrorMessages, SuccessMessage],
  methods: {
    async openPopup(id, name) {
      await Object.assign(this.$data, initialState());
      this.open = true;
      this.name = name;
      this.id = id;
    },
    async onClickSave() {
      const vue = this;
      this.$vs.loading();
      try {
        await NodeRepository.update(this.id, { Name: this.name });
        this.$vs.loading.close();
        this.showSuccessMessage('Group update successfully!');
        this.refreshGroups();
        this.open = false;
      } catch (e) {
        this.$vs.loading.close();
        await vue.$parent.dataRefresh(this.name);
        this.open = false;
        this.showErrorMessage('Something went wrong!');
      }
      await vue.$parent.dataRefresh(this.name);

      this.$vs.loading.close();
    },
    async refreshGroups() {
      vue.$emit('notify');
    },
  },
};
</script>

<style scoped>
</style>

<template>
  <vs-popup
    fullscreen
    class="product-add-popup"
    id="popup"
    :title="`Add Products To Group ${node.title}`"
    @close="open = false"
    :active.sync="open"
    :buttons-hidden="true"
  >
    <!--    <div class="vx-row mb-3  mt-8 ml-2">
      <div class="vx-col  md:w-full w-full md:mb-0">
        <span v-for="group in selected">
          <vs-chip class="mr-2" transparent color="#24c1a0">
            {{group}}
            {{
                 (products.find((p) => p.Id === group)).Name[0].Value
            }}
          </vs-chip>
        </span>
      </div>
    </div>-->
    <div class="centerx mb-2">
      <span class="mr-3"> E Pharmacy Status : </span>
      <span class="mr-3">
        <vs-radio vs-name="x" v-model="pharmacyStatus" :vs-value="null"
          >None</vs-radio
        >
      </span>
      <span class="mr-3">
        <vs-radio vs-name="x" v-model="pharmacyStatus" :vs-value="true"
          >Yes</vs-radio
        >
      </span>
      <span class="mr-3">
        <vs-radio vs-name="x" v-model="pharmacyStatus" :vs-value="false"
          >No</vs-radio
        >
      </span>

      <span class="mr-3">
        <vs-radio vs-name="x" v-model="pharmacyStatus" :vs-value="'null'"
          >N/A</vs-radio
        >
      </span>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col md:w-full w-full md:mb-0 px-8">
        <small class="label">Search Products</small>
        <vs-input
          autofocus
          @keyup.enter="onClickSearch"
          v-model="searchTerm"
          class="w-full"
          name="name"
          type="text"
          label=""
        />
      </div>
    </div>

    <div class="mt-4 vx-col md:w-full w-full md:mb-0">
      <div style="z-index: 1100" class="control_wrapper">
        <div id="data-list-list-view" class="data-list-container">
          <vs-table
            noDataText="No Item Available"
            ref="table"
            multiple
            v-model="selected"
            pagination
            :max-items="itemsPerPage"
            search
            :data="products"
          >
            <div
              slot="header"
              class="flex flex-wrap-reverse items-center flex-grow justify-between"
            >
              <h4>Select Products</h4>

              <!-- ITEMS PER PAGE -->
              <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
              >
                <div
                  class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                  <span class="mr-2"
                    >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
                    {{
                      products.length - currentPage * itemsPerPage > 0
                        ? currentPage * itemsPerPage
                        : products.length
                    }}
                    of {{ queriedItems }}</span
                  >
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
                <vs-dropdown-menu>
                  <vs-dropdown-item @click="itemsPerPage = 4">
                    <span>4</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="itemsPerPage = 10">
                    <span>10</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="itemsPerPage = 15">
                    <span>15</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="itemsPerPage = 20">
                    <span>20</span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>

            <template slot="thead">
              <!--              <vs-th sort-key="ProductId">Product Id</vs-th>-->
              <vs-th sort-key="Name">Name</vs-th>
              <vs-th sort-key="Code">Code</vs-th>
              <!--              <vs-th>Action</vs-th>-->
            </template>

            <template slot-scope="{ data }">
              <tbody>
                <vs-tr
                  :data="data[indextr].Id"
                  :key="indextr"
                  v-for="(tr, indextr) in data"
                >
                  <vs-td>
                    <p class="product-name font-medium truncate">
                      {{ tr.Name[0].Value }}
                    </p>
                  </vs-td>
                  <vs-td>
                    <p class="product-price">{{ tr.Code }}</p>
                  </vs-td>
                </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </div>
      </div>
    </div>
    <div
      v-if="products.length > 0"
      class="flex flex-wrap items-center justify-end mt-8"
    >
      <vs-button @click="onCLickSave" button="submit" class="ml-auto mt-2"
        >Save Changes</vs-button
      >
    </div>
  </vs-popup>
</template>

<script>
import { RepositoryFactory } from '@/repository/RepositoryFactory';
import ErrorMessages from '@/mixins/ErrorMessages';
import SuccessMessage from '@/mixins/SuccessMessage';

const NodeRepository = RepositoryFactory.get('node');
const ProductRepository = RepositoryFactory.get('product');

function initialState() {
  return {
    open: false,
    name: '',
    product_tree: [],
    map_data: [],
    products: [],
    fields: {},
    selected: [],
    // products: [],
    itemsPerPage: 10,
    isMounted: false,
    // Data Sidebar
    addNewDataSidebar: false,
    sidebarData: {},
    searchword: '',
    parent: {},
    node: {},
    searchTerm: '',
    pharmacyStatus: true,
  };
}

export default {
  name: 'ProductAddPopUp',
  data() {
    return initialState();
  },
  mixins: [ErrorMessages, SuccessMessage],
  methods: {
    async openPopup(node, parent) {
      await Object.assign(this.$data, initialState());
      this.open = true;
      await this.$vs.loading();
      this.parent = parent;
      this.node = node;
      this.isMounted = true;
      this.loadGroupProducts(node.Id);
      // await this.loadProducts()
      this.$vs.loading.close();
    },
    async loadGroupProducts(id) {
      const exists_data = (await NodeRepository.productByNode(id)).data;
      // exists_data ? this.selected = exists_data : this.selected = []
      console.log(exists_data);
      if (exists_data) {
        this.selected = exists_data.map((m) => {
          return m.Id;
        });
      } else {
        this.selected = [];
      }
      console.log(this.selected);
    },
    async onCLickSave() {
      const vue = this;
      console.log(this.selected);
      this.$vs.loading();
      try {
        await NodeRepository.addProductToTree({
          ProductId: this.selected,
          ParentId: this.node.Id,
        });
        this.$vs.loading.close();
        this.showSuccessMessage('Products add successfully!');
        // await vue.$parent.refreshTablelOad()
        this.open = false;
      } catch (e) {
        this.$vs.loading.close();
        // await vue.$parent.refreshTablelOad()
        this.open = false;
      }
      this.$vs.loading.close();
      vue.$emit('notify');
    },
    async loadProducts() {
      try {
        this.products = (
          await ProductRepository.search(this.searchTerm, {
            pharmacyStatus: this.pharmacyStatus,
          })
        ).data;
      } catch (e) {
        this.showErrorMessage('Something went wrong. Search again!');
      }
    },
    async onClickSearch() {
      this.$vs.loading();
      await this.loadProducts();
      this.$vs.loading.close();
    },
  },
  async mounted() {},
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.products.length;
    },
  },
};
</script>

<style scoped>
.product-add-popup >>> .vs-popup {
  min-height: 80% !important;
  width: 80% !important;
}

@media (min-width: 768px) and (max-width: 1319px) {
  .product-add-popup >>> .vs-popup {
    min-height: 80% !important;
    width: 80% !important;
  }
}
.items-per-page-handler {
  display: none;
}
</style>

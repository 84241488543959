<template>
  <div>
    <span v-if="!!data.node">
      <vs-button
        color="primary"
        class="ml-0"
        @click="addChild(data.node)"
        type="border"
        >Add Sub Group</vs-button
      >
      <vs-button
        color="primary"
        class="ml-4"
        @click="moveParent(data.node)"
        type="border"
        >Move To New Group</vs-button
      >
      <vs-button
        color="warning"
        class="ml-4"
        @click="editNode(data.node)"
        type="border"
        >Update</vs-button
      >
      <vs-button
        color="danger"
        class="ml-4"
        @click="openDeleteConfirm(data.node)"
        type="border"
        >Delete</vs-button
      >
    </span>
    <span v-if="!data.node">
      <small>Please select Group</small>
    </span>
    <update-node-pop-up ref="updateNodePopUp" />
    <child-add-node-pop-up ref="createNodePopUp" />
    <change-groups v-on:notify="dataRefresh" ref="changeGroup" />
    <div>
      <h4 class="mt-12 mb-5 ml-0">Translations</h4>
    </div>
    <translations-genaral-pop-up ref="translationGeneralPopUp" />
  </div>
</template>

<script>
import UpdateNodePopUp from '@/components/popups/node/UpdateNodePopUp';
import ChildAddNodePopUp from '@/components/popups/node/ChildAddNodePopUp';
import TranslationsGenaralPopUp from '@/components/popups/translations/TranslationsGenaralPopUp';
import ChangeGroups from '@/components/popups/node/ChangeGroups';
import { RepositoryFactory } from '@/repository/RepositoryFactory';
const NodeRepository = RepositoryFactory.get('node');
import SuccessMessage from '@/mixins/SuccessMessage';

export default {
  name: 'ProductsTableTab',
  components: {
    UpdateNodePopUp,
    ChildAddNodePopUp,
    TranslationsGenaralPopUp,
    ChangeGroups,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    productInCart: Number,
  },
  mixins: [SuccessMessage],
  mounted() {
    this.openTranslationPopUp(this.data.node);
  },
  methods: {
    async dataRefresh() {
      const vue = this;
      vue.$emit('notify');
    },
    async editNode(node) {
      await this.$refs.updateNodePopUp.openPopup(node.Id, node.title);
    },
    async addChild(node) {
      await this.$refs.createNodePopUp.openPopup(node);
    },
    async openTranslationPopUp(node) {
      await this.$refs.translationGeneralPopUp.openPopup(node);
    },
    async moveParent(node) {
      await this.$refs.changeGroup.openPopup(node);
    },
    openDeleteConfirm() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm',
        text: 'Are you sure you want to permanently remove this group?',
        accept: this.deleteNode,
      });
    },
    async deleteNode() {
      const vue = this;
      this.$vs.loading();
      await NodeRepository.delete(this.data.node.Id);
      // await this.loadTree()
      this.showSuccessMessage('The selected group was successfully deleted');
      this.$vs.loading.close();
      await this.dataRefresh();
      vue.$emit('initialDataLoadNotify');
    },
  },
};
</script>

<style scoped>
</style>

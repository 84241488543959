<template>
  <vs-popup
    class="user-edit-popup"
    id="popup"
    title=" Group Create"
    @close="open = false"
    :active.sync="open"
    :buttons-hidden="true"
  >
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onCLickSave)" autocomplete="off">
        <div class="vx-row mb-3">
          <div class="vx-col md:w-full w-full md:mb-0">
            <ValidationProvider
              vid="name"
              name="name"
              rules="required"
              v-slot="{ errors }"
            >
              <small class="label">Name</small>
              <vs-input
                v-model="name"
                class="w-full"
                name="name"
                type="text"
                label=""
              />
              <span class="text-danger text-sm">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="flex flex-wrap items-center justify-end mt-8">
          <vs-button button="submit" class="ml-auto mt-2"
            >Save Changes</vs-button
          >
          <!--        <vs-button @click="name = ''" class="ml-4 mt-2" type="border" color="warning">Clear</vs-button>-->
        </div>
      </form>
    </ValidationObserver>
  </vs-popup>
</template>

<script>
import { RepositoryFactory } from '@/repository/RepositoryFactory';
const NodeRepository = RepositoryFactory.get('node');
import ErrorMessages from '@/mixins/ErrorMessages';
import SuccessMessage from '@/mixins/SuccessMessage';

function initialState() {
  return {
    open: false,
    name: '',
  };
}

export default {
  name: 'RootAddNodePopUp',
  data() {
    return initialState();
  },
  mixins: [ErrorMessages, SuccessMessage],
  methods: {
    async openPopup() {
      await Object.assign(this.$data, initialState());
      this.open = true;
    },
    async onCLickSave() {
      const vue = this;
      this.$vs.loading();
      try {
        await NodeRepository.rootCreate({ Name: this.name, ParentId: null });
        this.$vs.loading.close();
        this.showSuccessMessage('Group create successfully!');
        await vue.$emit('initialDataLoadNotify');
        this.open = false;
      } catch (e) {
        this.showErrorMessage('Something went wrong!');
        this.$vs.loading.close();
      }
      this.$vs.loading.close();
    },
  },
};
</script>

<style scoped>
</style>

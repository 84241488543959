<template>
  <vx-card no-shadow>
    <div class="vx-row mb-3">
      <div class="vx-col flex-grow md:mb-5 tree-view-wr">
        <!--          <ejs-treeview :nodeClicked="nodeChecked" :allowEditing="true" :nodeEditing="editing" id="treeview" :fields="fields"></ejs-treeview>-->
        <div class="d-flex clearfix mb-5">
          <vs-input
            class="inputx tree-search-input float-left mr-1"
            placeholder="Search..."
            v-model.lazy="searchword"
          />
          <feather-icon
            @click="treeRefreshWithLanguage"
            icon="XIcon"
            svgClasses="w-5 h-5 hover:text-danger stroke-current"
            class="d-inline-flex float-left ml-2 px-2 py-2 cursor-pointer clear-button"
          />
          <span v-if="searchword !== ''">
            <vs-button
              @click="search"
              radius
              icon="icon-search"
              icon-pack="feather"
              class="d-inline-flex float-left"
            />
          </span>
          <span v-else>
            <vs-button
              @click="treeRefreshWithLanguage"
              radius
              icon="icon-search"
              icon-pack="feather"
              class="d-inline-flex float-left"
            />
          </span>
          <vs-button
            color="primary"
            type="border"
            @click="addRootNode"
            class="mb-5 ml-1 float-right"
            >Add Group</vs-button
          >
        </div>
        <div class="d-flex mb-4">
          <vs-button
            @click="changeLan('EST')"
            class="mr-4"
            v-bind:class="{ 'button-highlighted': lan === 'EST' }"
            size="small"
            color="success"
            type="border"
            icon-pack="feather"
            >EST</vs-button
          >
          <vs-button
            :key="lan_arr"
            v-for="lan_arr in current_user_language_array"
            @click="changeLan(lan_arr)"
            v-bind:class="{ 'button-highlighted': lan === lan_arr }"
            class="mr-4"
            size="small"
            color="success"
            type="border"
            icon-pack="feather"
            >{{ lan_arr }}</vs-button
          >
        </div>
        <v-tree
          v-if="treeData"
          ref="tree"
          :canDeleteRoot="true"
          :data="treeData"
          :draggable="true"
          :tpl="tpl"
          :halfcheck="true"
          :multiple="true"
        />
      </div>
      <div class="vx-col-sm-6 flex-grow md:mb-0">
        <span>
          <div slot="no-body" class="tabs-container px-0">
            <vs-tabs class="tab-action-btn-fill-conatiner">
              <vs-tab
                :label="Test + selectedGroupName.ProductCount"
                icon-pack="feather"
                icon="icon-user"
              >
                <div class="tab-text">
                  <div class="control_wrapper">
                    <div id="data-list-list-view" class="data-list-container">
                      <h2 class="mt-5 mb-5 ml-0">
                        <span class="text-boldmr-4" v-if="selectedGroupName">
                          <!--                         {{group.Value}}-->
                          {{
                            __translations(selectedGroupName.Name) +
                            `(${selectedGroupName.ProductCount})`
                          }}
                        </span>
                      </h2>

                      <vs-table
                        class="table-000"
                        noDataText="No Item Available In Selected Group"
                        ref="table"
                        v-model="selected"
                        pagination
                        :max-items="itemsPerPage"
                        search
                        :data="products"
                      >
                        <div
                          slot="header"
                          class="flex flex-wrap-reverse items-center flex-grow justify-between"
                        >
                          <div
                            class="flex tems-center data-list-btn-container mr-0"
                          >
                            <!-- ACTION - DROPDOWN -->

                            <!-- ADD NEW -->
                            <vs-button
                              color="primary"
                              type="filled"
                              @click="onClickProductAddToGroup"
                              class="mr-3"
                              >Add Products</vs-button
                            >
                            <vs-button
                              color="warning"
                              type="border"
                              @click="onClickMoveParent()"
                              class="float-left"
                              >Move To New Parent</vs-button
                            >
                          </div>

                          <!-- <div class="flex flex-wrap-reverse items-center data-list-btn-container mr-0">
                          <vs-input placeholder="Search" v-model="value1" class="inputx tree-search-input float-left mr-1 mb-8"/>
                          <vs-button radius color="primary" type="border" icon="search" class="mb-8 ml-1 float-right"  @click="loadProducts()"></vs-button>
                          </div> -->
                          <!-- <vs-input icon-after="true" label-placeholder="icon-after" icon="mode_edit" placeholder="Nombre" v-model="value2"/> -->
                          <!-- ITEMS PER PAGE -->
                          <!--<vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
                            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                              <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ products.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : products.length }} of {{ queriedItems }}</span>
                              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                            </div>
                            &lt;!&ndash; <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> &ndash;&gt;
                            <vs-dropdown-menu>

                             &lt;!&ndash; <vs-dropdown-item @click="itemsPerPage=4">
                                <span>4</span>
                              </vs-dropdown-item>&ndash;&gt;
                              <vs-dropdown-item @click="itemsPerPage=10">
                                <span>10</span>
                              </vs-dropdown-item>
                              <vs-dropdown-item @click="itemsPerPage=15">
                                <span>15</span>
                              </vs-dropdown-item>
                              <vs-dropdown-item @click="itemsPerPage=20">
                                <span>20</span>
                              </vs-dropdown-item>
                            </vs-dropdown-menu>
                          </vs-dropdown>-->
                        </div>

                        <template slot="thead">
                          <!--                          <vs-th sort-key="ProductId">Product Id</vs-th>-->
                          <vs-th sort-key="Name">Name</vs-th>
                          <vs-th sort-key="Code">Code</vs-th>
                          <!--                <vs-th sort-key="Code">STATUS</vs-th>-->
                          <!--                <vs-th sort-key="popularity">Popularity</vs-th>-->
                          <!--                <vs-th sort-key="order_status">Order Status</vs-th>-->
                          <!--                <vs-th sort-key="price">Price</vs-th>-->
                          <vs-th>Action</vs-th>
                        </template>

                        <template slot-scope="{ data }">
                          <tbody>
                            <vs-tr
                              :data="tr"
                              :key="indextr"
                              v-for="(tr, indextr) in data"
                            >
                              <!-- <vs-td>
                              <p class="product-name font-medium truncate">{{ tr.Id }}</p>
                            </vs-td>-->

                              <vs-td>
                                <p class="product-name font-medium truncate">
                                  <!--                                {{ tr.Name.find((n) => n.Language = 'EST').Value }}-->
                                  {{ __translations(tr.Name) }}
                                </p>
                                <!--                              <p class="product-name product-name__lang font-medium truncate"><span>{{ tr.Name[1] ? tr.Name[1].Value : ''}}</span><span>{{ tr.Name[2] ? tr.Name[2].Value : ''}}</span></p>-->
                              </vs-td>

                              <vs-td>
                                <p class="product-price">{{ tr.Code }}</p>
                              </vs-td>

                              <!--                  <vs-td>-->
                              <!--                    <vs-chip :color="'warning'" class="product-order-status">PENDING</vs-chip>-->
                              <!--                  </vs-td>-->

                              <vs-td class="whitespace-no-wrap">
                                <!--                                  @click.stop="editData(tr)" -->

                                <feather-icon
                                  @click="
                                    openConfirmDelete(
                                      tr.RelationId,
                                      tr.ParentId,
                                    )
                                  "
                                  icon="TrashIcon"
                                  svgClasses="w-5 h-5 hover:text-danger stroke-current"
                                  class="ml-2"
                                />
                                <feather-icon
                                  class="ml-2"
                                  @click="
                                    moveProductToAnotherNode(
                                      tr.RelationId,
                                      __translations(tr.Name),
                                    )
                                  "
                                  icon="CornerUpRightIcon"
                                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                                />
                                <!--                                  @click.stop="deleteData(tr.id)" -->
                              </vs-td>
                            </vs-tr>
                          </tbody>
                        </template>
                      </vs-table>
                    </div>
                  </div>
                  <!--                  <user-edit-tab-information class="mt-4" :data="user_data" />-->
                </div>
              </vs-tab>
              <vs-tab label="Groups" icon-pack="feather" icon="icon-info">
                <h2 class="mt-5 mb-5 ml-0">
                  <span class="text-boldmr-4" v-if="selectedGroupName">
                    <!--                         {{group.Value}}-->
                    {{ __translations(selectedGroupName.Name) }}
                  </span>
                </h2>

                <div class="tab-text">
                  <!--                  <user-edit-tab-information class="mt-4" :data="user_data" />-->
                  <products-table-tab
                    ref="productsTableTab"
                    v-on:initialDataLoadNotify="initialTreeGroupLoad"
                    v-on:notify="notify"
                    class="mt-4"
                    :data="selectedData"
                  />
                </div>
              </vs-tab>
            </vs-tabs>
          </div>
        </span>
      </div>
    </div>
    <update-node-pop-up v-on:notify="refreshGroup" ref="updateNodePopUp" />
    <child-add-node-pop-up ref="createNodePopUp" />
    <root-add-node-pop-up
      v-on:initialDataLoadNotify="loadTree"
      ref="addRootNodePopUp"
    />
    <product-add-pop-up
      v-on:notify="refreshProductTable"
      ref="productAddPopUp"
    />
    <!--    <translations-genaral-pop-up ref="translationGeneralPopUp"/>-->
    <product-move-to-another-pop-up
      v-on:notify="refreshProductTable"
      ref="productMoveAnotherNodePoUp"
    />
    <move-to-parent-pop-up
      v-on:notify="refreshProductTable"
      :data="selectedData"
      ref="moveToParent"
    />
  </vx-card>
</template>

<script>
import ProductsTableTab from './ProductsTableTab.vue';

import Vue from 'vue';
import { TreeViewPlugin } from '@syncfusion/ej2-vue-navigations';
import { VSelectTree, VTree } from 'vue-tree-halower';
import { RepositoryFactory } from '@/repository/RepositoryFactory';
import UpdateNodePopUp from '@/components/popups/node/UpdateNodePopUp';
import ChildAddNodePopUp from '@/components/popups/node/ChildAddNodePopUp';
import RootAddNodePopUp from '@/components/popups/node/RootAddNodePopUp';
import ProductAddPopUp from '@/components/popups/node/ProductAddPopUp';
import TranslationsGenaralPopUp from '@/components/popups/translations/TranslationsGenaralPopUp';
import ProductMoveToAnotherPopUp from '@/components/popups/node/ProductMoveToAnotherPopUp';
//import moveToParentPopUp from '@/components/popups/node/MoveToParentPopUp'
import MoveToParentPopUp from '@/components/popups/node/MoveToParentPopUp';

import SuccessMessage from '@/mixins/SuccessMessage';
import store from '../../../store/store';

Vue.use(TreeViewPlugin);

const NodeRepository = RepositoryFactory.get('node');
const ProductRepository = RepositoryFactory.get('product');

export default {
  name: 'NodeManageGenaral',
  components: {
    VTree,
    VSelectTree,
    UpdateNodePopUp,
    ChildAddNodePopUp,
    RootAddNodePopUp,
    ProductAddPopUp,
    TranslationsGenaralPopUp,
    ProductMoveToAnotherPopUp,
    ProductsTableTab,
    MoveToParentPopUp,
  },
  data() {
    return {
      product_tree: [],
      map_data: [],
      products: [],
      fields: {},
      tree_data: [],
      selected: [],
      // products: [],
      itemsPerPage: 10,
      isMounted: false,
      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
      searchword: '',
      treeData: [],
      selectedDeleteGroupId: null,
      activeConfirm: false,
      selectedGroupName: '',
      selectedData: {},
      initialTreeData: [],
      lan: 'EST',
      current_user_language_array: store.getters.current_user_language_array,
      pharmacyStatus: null,
      Test: 'Products - ',
      parentId: '',
    };
  },
  mixins: [SuccessMessage],
  async mounted() {
    await this.$vs.loading();
    this.isMounted = true;

    // end tree test

    await this.loadTree();
    await this.initialTreeGroupLoad();
    // await this.loadProducts()
    // this.map_data = tree_data.map((n) => {
    //   return {Id: n.Id, ParentId: n.ParentId, Name: n.Name[0].Value, hasChild: true}
    // })
    // this.treeLoad()
    this.$vs.loading.close();
  },
  methods: {
    async notify() {
      this.$vs.loading();
      await this.loadTree();
      await this.getOneNodeDetails(this.selectedGroupName.Id);
      await this.openTranslationPopUp(this.selectedGroupName);
      this.$vs.loading.close();
    },
    async changeLan(lan) {
      this.lan = lan;
      await this.treeRefreshWithLanguage();
    },
    async treeRefreshWithLanguage() {
      await this.$vs.loading();
      this.searchword = '';
      await this.loadTree();
      this.$vs.loading.close();
    },
    async onClickProductAddToGroup() {
      await this.addProducts(this.selectedData.node, this.selectedData.parent);
    },
    async refreshProductTable() {
      await this.$vs.loading();
      await this.viewProducts(this.selectedData.node, this.selectedData.parent);
      await this.loadTree();
      this.$vs.loading.close();
    },
    async refreshGroup() {
      this.selectedGroupName = (await NodeRepository.productByNode(id)).data;
    },
    search() {
      // this.$refs.tree.searchNodes(this.searchword)
      if (this.searchword.length > 0) {
        this.$refs.tree.searchNodes((node) =>
          new RegExp(this.searchword, 'i').test(node.title),
        );
      }
    },
    tpl(...args) {
      const { 0: node, 2: parent, 3: index } = args;
      let titleClass = node.selected ? 'node-title' : 'node-title'; //node-selected janaka - classes to selected node highlight
      if (node.searched) titleClass += ' node-searched';
      return (
        <span>
          <span
            class={titleClass}
            domPropsInnerHTML={node.title + ` (${node.ProductCount})`}
            onClick={() => {
              this.viewProducts(node, parent);
              //this.refreshSelectedData(node, parent)
              this.$refs.tree.nodeSelected(node);
              this.$refs.productsTableTab.openTranslationPopUp(node);
            }}
          ></span>
        </span>
      );
      // <button class="btn-warning text-warning border-none cursor-pointer px-2 py-1 rounded" onClick={() => this.viewProducts(node, parent)}>view</button>
    },
    // <button class="btn-success text-primary border-none cursor-pointer px-2 py-1 rounded" onClick={() => this.addProducts(node, parent)}>add products</button>
    // <button class="btn-warning text-warning border-none cursor-pointer px-2 py-1 rounded" onClick={() => this.viewProducts(node)}>view</button>
    // <button class="btn-success text-success border-none cursor-pointer px-2 py-1 rounded" onClick={() => this.addChild(node, parent)}>add</button>
    // <button class="btn-warning text-warning border-none cursor-pointer px-2 py-1 rounded" onClick={() => this.editNode(node, parent)}>edit</button>
    // <button class="btn-delete text-danger border-none cursor-pointer px-2 py-1 rounded" onClick={() => this.openDeleteConfirm(node)}>delete</button>
    // <button class="btn-delete text-danger border-none cursor-pointer px-2 py-1 rounded" onClick={() => this.openTranslationPopUp(node)}>translations</button>

    async addChild(node, parent) {
      await this.$refs.createNodePopUp.openPopup(node);
    },
    async editNode(node, parent) {
      await this.$refs.updateNodePopUp.openPopup(node.Id, node.title);
    },
    async addRootNode() {
      await this.$refs.addRootNodePopUp.openPopup();
    },
    async addProducts(node, parent) {
      await this.$refs.productAddPopUp.openPopup(node, parent);
    },
    async onClickMoveParent() {
      this.$vs.loading();
      await this.$refs.moveToParent.openPopup(this.products);
      this.$vs.loading.close();
    },
    async onClickAddParent() {
      await this.$refs.moveToParent.openPopup();
    },
    async openTranslationPopUp(selectedGroupName) {
      //await this.$refs.translationGeneralPopUp.openPopup(selectedGroupName)
    },
    openConfirmDelete(RelationId, ParentId) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm',
        text: 'Are you sure want to delete selected product from group?',
        accept: this.onClickProductDeleteFromNode,
        parameters: [RelationId, ParentId],
      });
    },
    async onClickProductDeleteFromNode(params) {
      this.$vs.loading();
      await NodeRepository.deleteProductFromNode(params[0]);
      await this.viewProducts({ Id: params[1] });
      this.$vs.loading.close();
    },
    async viewProducts(node, parent) {
      this.$vs.loading();
      this.selectedData.node = node;
      this.selectedData.parent = parent;
      this.products = (await NodeRepository.productByNode(node.Id)).data;
      this.selectedGroupName = node;
      console.log(this.selectedData);
      this.$vs.loading.close();
    },
    async getOneNodeDetails(id) {
      const nodeDetails = (await NodeRepository.getOne(id)).data;
      this.selectedGroupName = nodeDetails[0];
    },
    async deleteNode() {
      this.$vs.loading();
      await NodeRepository.delete(this.selectedDeleteGroupId);
      await this.loadTree();
      this.showSuccessMessage('The selected group was successfully deleted');
      this.$vs.loading.close();
    },
    async loadTree() {
      const tree_data = (await NodeRepository.get()).data;
      this.initialTreeData = tree_data;
      this.tree_data = tree_data;
      // tree test
      const nest = (items, Id = null, link = 'ParentId') =>
        items
          .filter((item) => item[link] === Id)
          .map((item) => ({
            ...item,
            children: nest(items, item.Id),
            title: this.treeTranslations(item.Name),
            ProductCount: item.ProductCount,
            expanded: false,
            nocheck: true,
          }));
      // (nest(items, item.Id)).length > 0

      this.treeData = nest(tree_data);
      console.log(this.treeData);
    },

    async initialTreeGroupLoad() {
      await this.viewProducts(this.treeData[0], null);
    },
    openDeleteConfirm(node) {
      this.selectedDeleteGroupId = node.Id;
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm',
        text: 'Are you sure you want to permanently remove this group?',
        accept: this.deleteNode,
      });
    },
    async moveProductToAnotherNode(relationId, productName) {
      await this.$refs.productMoveAnotherNodePoUp.openPopup(
        relationId,
        productName,
      );
    },
    acceptAlert() {
      this.$vs.notify({
        color: 'danger',
        title: 'Deleted image',
        text: 'The selected image was successfully deleted',
      });
    },
    treeTranslations(Name) {
      if (!Name) {
        return '';
      }
      const name = Name.find((n) => {
        return n.Language === this.lan;
      });
      if (!!name) {
        return name.Value;
      } else {
        return Name.find((n) => {
          return n.Language === 'EST';
        }).Value;
      }
    },
    janaka(lan) {
      if (lan === this.lan) {
        return 'button-highlighted';
      } else {
        return '';
      }
    },
  },

  computed: {
    currentPage() {
      if (this.isMounted) {
        // return this.$refs.table.currentx
      }
      return 0;
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.products.length;
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/scss/vuexy/extraComponents/tree.scss';

button.btn-async {
  background: rgba(var(--vs-warning), 0.15);
}
.button-highlighted {
  background: #64c272 !important;
  color: white !important;
}
.tree-view-wr {
  max-width: 450px;
}

button.btn-delete {
  background: rgba(var(--vs-danger), 0.15);
}
button.btn-success {
  background: rgba(var(--vs-success), 0.15);
}
button.btn-warning {
  background: rgba(var(--vs-warning), 0.15);
}
#data-list-list-view {
  .vs-con-table {
    /*
        Below media-queries is fix for responsiveness of action buttons
        Note: If you change action buttons or layout of this page, Please remove below style
      */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 95%;

      &__lang {
        font-size: 0.75rem;
        opacity: 0.75;

        span {
          margin-right: 10px;
        }
      }
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 1px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
.table-000 {
  .vs-table {
    tr {
      td {
        padding: 8px !important;
      }
    }
  }
}
.clear-button {
  margin-left: -40px !important;
  margin-top: 2px !important;
}
</style>

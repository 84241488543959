<template>
  <vs-popup
    class=""
    id="popup"
    title="Move Group"
    @close="open = false"
    :active.sync="open"
    :buttons-hidden="true"
  >
    <div class="vx-row mb-3">
      <div class="vx-col md:w-full w-full md:mb-0">
        <small class="label">Product Name</small>
        <vs-input
          v-model="productName"
          class="w-full"
          name="name"
          disabled
          type="text"
          label=""
        />
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col md:w-full w-full md:mb-0" style="min-height: 300px">
        <small class="label">New Group</small>
        <!--        <vs-input readonly v-model="parentNode" class="w-full" name="name" type="text" label=""/>-->

        <v-select
          label="Name"
          v-model="new_parent"
          :closeOnSelect="true"
          :options="parent_map"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        >
          <template v-slot:option="option">
            {{ option.ParentData ? option.ParentData.Name + ' ->' : '' }}
            {{ option.Name }}
          </template>
        </v-select>

        <!--        <v-select-tree :data="data"  :multiple="true"/>-->
      </div>
    </div>
    <div class="flex flex-wrap items-center justify-end mt-8">
      <vs-button
        @click="onCLickSave"
        id="save_change"
        button="submit"
        class="ml-auto mt-2"
        >Save Changes</vs-button
      >
      <!--      <vs-button @click="name = ''" class="ml-4 mt-2" type="border" color="warning">Clear</vs-button>-->
    </div>
  </vs-popup>
</template>

<script>
import { VSelectTree, VTree } from 'vue-tree-halower';

import { RepositoryFactory } from '@/repository/RepositoryFactory';
import ErrorMessages from '@/mixins/ErrorMessages';
import SuccessMessage from '@/mixins/SuccessMessage';
import vSelect from 'vue-select';

const ProductRepository = RepositoryFactory.get('product');
const NodeRepository = RepositoryFactory.get('node');

function initialState() {
  return {
    open: false,
    name: '',
    parentId: null,
    parentNode: '',
    initSelected: null,
    new_parent: null,
    relationId: null,
    new_map: [],
    parent_map: [],
    productName: '',
  };
}
export default {
  name: 'ChildAddNodePopUp',
  data() {
    return initialState();
  },
  components: {
    VTree,
    VSelectTree,
    'v-select': vSelect,
  },
  mixins: [ErrorMessages, SuccessMessage],
  methods: {
    async openPopup(relationId, productName) {
      await Object.assign(this.$data, initialState());
      this.relationId = relationId;
      this.productName = productName;
      await this.initialTreeData();
      this.open = true;
    },
    async initialTreeData() {
      this.$vs.loading();
      const initialTreeData = (await NodeRepository.get()).data;
      this.new_map = initialTreeData.map((m) => {
        m.Name = m.Name[0].Value;
        return m;
      });
      console.log(this.new_map);
      this.parent_map = this.new_map.map((m) => {
        const parent = this.new_map.find((n) => n.Id === m.ParentId);
        m.ParentData = parent;
        return m;
      });
      this.$vs.loading.close();
    },
    async onCLickSave() {
      console.log(this.new_parent.Id);
      console.log(this.relationId);
      const vue = this;
      this.$vs.loading({
        container: '#save_change',
        scale: 0.45,
      });
      try {
        await ProductRepository.parentMove(this.relationId, {
          ParentId: this.new_parent.Id,
        });
        this.$vs.loading.close();
        this.showSuccessMessage('Product move successfully!');
        this.open = false;
      } catch (e) {
        this.$vs.loading.close('#save_change > .con-vs-loading');
        this.open = false;
        this.showErrorMessage('Something went wrong!');
      }
      this.$vs.loading.close('#save_change > .con-vs-loading');
      vue.$emit('notify');
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/vuexy/extraComponents/tree.scss';

.tree-box {
  background: #fff;
  position: relative;
  z-index: 99999;

  .search-input {
    margin-top: 10px;
    width: 98%;
    display: block;
  }
}

.rmNode {
  background-color: rgba(var(--vs-danger), 0.15);
  color: rgba(var(--vs-danger), 1);
  line-height: 13px;
}
</style>

<template>
  <vs-popup
    class="user-edit-popup"
    id="popup"
    title=" Create Sub Group"
    @close="open = false"
    :active.sync="open"
    :buttons-hidden="true"
  >
    <div class="vx-row mb-3">
      <div class="vx-col md:w-full w-full md:mb-0">
        <small class="label">Parent Group</small>
        <vs-input
          readonly
          v-model="parentNode"
          class="w-full"
          name="name"
          type="text"
          label=""
        />
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col md:w-full w-full md:mb-0">
        <small class="label">Name</small>
        <vs-input
          v-model="name"
          class="w-full"
          name="name"
          type="text"
          label=""
        />
      </div>
    </div>
    <div class="flex flex-wrap items-center justify-end mt-8">
      <vs-button
        id="save_change"
        @click="onCLickSave"
        button="submit"
        class="ml-auto mt-2"
        >Save Changes</vs-button
      >
      <!--      <vs-button @click="name = ''" class="ml-4 mt-2" type="border" color="warning">Clear</vs-button>-->
    </div>
  </vs-popup>
</template>

<script>
import { RepositoryFactory } from '@/repository/RepositoryFactory';
const NodeRepository = RepositoryFactory.get('node');
import ErrorMessages from '@/mixins/ErrorMessages';
import SuccessMessage from '@/mixins/SuccessMessage';

function initialState() {
  return {
    open: false,
    name: '',
    parentId: null,
    parentNode: '',
  };
}
export default {
  name: 'ChildAddNodePopUp',
  data() {
    return initialState();
  },
  mixins: [ErrorMessages, SuccessMessage],
  methods: {
    async openPopup(node) {
      await Object.assign(this.$data, initialState());
      this.open = true;
      this.parentNode = node.title;
      this.parentId = node.Id;
    },
    async onCLickSave() {
      const vue = this;
      this.$vs.loading({
        container: '#save_change',
        scale: 0.45,
      });
      try {
        await NodeRepository.childCreate({
          Name: this.name,
          ParentId: this.parentId,
        });
        this.$vs.loading.close();
        this.showSuccessMessage('Sub Group create successfully!');
        await vue.$parent.dataRefresh();
        this.open = false;
      } catch (e) {
        this.$vs.loading.close('#save_change > .con-vs-loading');
        await vue.$parent.dataRefresh();
        this.open = false;
        this.showErrorMessage('Something went wrong!');
      }
      await vue.$parent.dataRefresh();
      this.$vs.loading.close('#save_change > .con-vs-loading');
    },
  },
};
</script>

<style scoped>
</style>
